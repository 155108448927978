import { useNavigate } from 'react-router-dom'
import { Card } from '@mantine/core'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { CreateClientRequest, createClientRequestSchema } from '@ospace/schemas'
import { ErrorBoundary, FormInit } from '@ospace/shared'

import { ClientForm } from '../components/ClientForm'
import { useCreateClientMutation } from '../hooks'

const inits: FormInit<CreateClientRequest & { logoFile: File }> = {
  name: '',
  website: '',
  logoFile: '',
  distributionPipelineVendorId: '',
  salesDashboardVendorId: '',
  currency: '',
  showDistributionPipelineToExternalUser: false,
  showSalesDashboardToExternalUser: false,
  showLeadsDashboardToExternalUser: false,
  enableVendorSalesPersonFilter: true,
  JIWARegion: '',
  showNotes: false,
  showTeams: false,
  FYEndMonth: '',
  teamMembers: [],
  region: 'au',
}

const CreateClientPage = () => {
  const navigate = useNavigate()
  const mutation = useCreateClientMutation()

  const onSubmit = async (values: any, form: any) => {
    await mutation.mutateAsync({
      name: values.name,
      region: values.region,
      JIWARegion: values.JIWARegion,
      website: values.website,
      logoFile: values.logoFile === '' ? null : values.logoFile,
      distributionPipelineVendorId: values.distributionPipelineVendorId,
      salesDashboardVendorId: values.salesDashboardVendorId,
      currency: values.currency,
      showDistributionPipelineToExternalUser: Boolean(
        values.showDistributionPipelineToExternalUser
      ),
      showSalesDashboardToExternalUser: Boolean(values.showSalesDashboardToExternalUser),
      showLeadsDashboardToExternalUser: Boolean(values.showLeadsDashboardToExternalUser),
      FYEndMonth: values.FYEndMonth,
      showNotes: Boolean(values.showNotes),
      showTeams: Boolean(values.showTeams),
      teamMembers: values.teams,
    })
    navigate(`/`)
  }

  return (
    <Card p='md'>
      <PageTitle>Create Client</PageTitle>
      <ClientForm
        initialValues={inits}
        isSubmitting={mutation.isLoading}
        onSubmit={onSubmit}
        validationSchema={createClientRequestSchema}
      />
    </Card>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager])
  return <CreateClientPage />
}

export const CreateClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
