import { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Select } from '@mantine/core'
import { CampaignPage } from '@ospace/campaign/pages/CampaignPage/CampaignPage'
import { ErrorBoundary } from '@ospace/shared'

import { Notes } from '../components/Notes'
import { useAccountInsights } from '../hooks'

type AccountInsightsBoxPageProps = { campaignId: number }

const ALL = 'All'
const AccountInsightsPage = (props: AccountInsightsBoxPageProps) => {
  const { isFetching, data: AccountInsights } = useAccountInsights(props.campaignId)
  const [vendorSalesPersonEmail, setVendorSalesPersonEmail] = useState<string | null>('')
  const [vendorSalesPersons, setVendorSalesPersons] = useState<{ label: string; value: string }[]>(
    []
  )
  const [notes, setNotes] = useState<any[]>([])
  const [filteredNotes, setFilteredNotes] = useState<any[]>([])

  useEffect(() => {
    if (!AccountInsights) return
    setNotes(AccountInsights.notes)
    setFilteredNotes(AccountInsights.notes)
    setVendorSalesPersons(
      [
        {
          label: ALL,
          value: ALL,
        },
      ].concat(
        Object.entries(AccountInsights.vendorSalesPerson.data).map(([email, value]) => {
          const { firstname, lastname } = value as {
            firstname: string
            lastname: string
          }
          return {
            label: `${firstname} ${lastname}`,
            value: email,
          }
        })
      )
    )
  }, [AccountInsights])

  useEffect(() => {
    if (!vendorSalesPersonEmail) return

    if (vendorSalesPersonEmail === ALL) {
      setFilteredNotes(AccountInsights.notes)
      return
    }

    setFilteredNotes(
      notes.filter((note) =>
        AccountInsights.vendorSalesPerson.data[vendorSalesPersonEmail].companyIds.includes(
          note.company.id
        )
      )
    )
  }, [AccountInsights, notes, vendorSalesPersonEmail])

  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            <div className={`card`}>
              <div className='card-header border-0'>
                <h3
                  className='card-title align-items-start flex-column'
                  style={{
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    <span className='card-label fw-bolder fs-3'>Account Insights</span>
                    {AccountInsights.vendorSalesPerson.enabled && (
                      <Select
                        data={vendorSalesPersons}
                        value={vendorSalesPersonEmail}
                        onChange={setVendorSalesPersonEmail}
                        label={`Vendor sales person (${filteredNotes.length})`}
                      />
                    )}
                  </div>
                </h3>
              </div>
              <div className={`card-body`} style={{ overflow: 'scroll' }}>
                <Notes notes={filteredNotes} />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)

  return <AccountInsightsPage campaignId={campaignId} />
}

export const AccountInsightsPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
