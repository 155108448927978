import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { CampaignStatus } from '@ospace/schemas'
import { ErrorBoundary } from '@ospace/shared'

import { useCampaign, useCampaignDashboard } from '../../hooks'

import { CampaignPage } from './CampaignPage'

type IntelType = 'intent' | 'contact'
type Props = {
  campaignId: number
  intelType: IntelType
}
export const DataIntelPage = (props: Props) => {
  const { campaignId, intelType } = props
  const { data: campaign, isFetching: isFetchingCampaign } = useCampaign(campaignId)
  const { data: dashboards, isFetching } = useCampaignDashboard(campaignId, intelType)

  let dashboardUrl
  if (props.intelType === 'intent') {
    dashboardUrl = dashboards?.dashboardForIntent
    if (campaign?.status !== CampaignStatus.IN_PROGRESS) {
      dashboardUrl = dashboards?.endedDashboardForIntent
    }
  } else if (props.intelType === 'contact') {
    dashboardUrl = dashboards?.dashboardForContact
  }
  return (
    <CampaignPage>
      {isFetching && isFetchingCampaign ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <iframe title='intent' width='100%' style={{ height: '100vh' }} src={dashboardUrl} />
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const { id, intelType }: any = useParams()
  return <DataIntelPage campaignId={Number(id)} intelType={intelType} />
}

export const DataIntelPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
