import { Card, Group, Text, Tooltip } from '@mantine/core'

import { CopyWidgetButton } from './copy-widget-button'
import { DisplayAmount, formattedAmount } from './DisplayAmount'

export const PipelineStatus = ({
  currency,
  pipelineStatusData,
  isLoading,
}: {
  pipelineStatusData:
    | {
        mql: { value: number; tooltip: string }
        sql: { value: number; tooltip: string }
        qualified: { value: number; tooltip: string }
        currentARR: { value: number; tooltip: string }
      }
    | undefined
  isLoading: boolean
  currency: string
}) => {
  const { targetRef, copyButton } = CopyWidgetButton({ title: 'Pipeline Status' })

  return (
    <Card
      shadow='sm'
      radius='md'
      h='100%'
      withBorder
      c='#868e96'
      ref={targetRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Group position='apart'>
        <Text fw={600}>PIPELINE STATUS:</Text>
        {copyButton}
      </Group>
      <Text fz='sm'>MONTH 4 OF 12 MONTHS PROGRAM</Text>

      {[
        {
          label: 'MQL Stage',
          value: pipelineStatusData?.mql.value,
          tooltip: pipelineStatusData?.mql.tooltip,
        },
        {
          label: 'SQL Stage',
          value: pipelineStatusData?.sql.value,
          tooltip: pipelineStatusData?.sql.tooltip,
        },
        {
          label: 'Qualified',
          value: pipelineStatusData?.qualified.value,
          tooltip: pipelineStatusData?.qualified.tooltip,
        },
      ].map(({ label, value, tooltip }) => (
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 10,
          }}
          key={label}
        >
          <Text>{label}</Text>

          <Group spacing={3}>
            <Text fw={600} fz={20} c={'#000'}>
              {currency}
            </Text>
            <Tooltip label={tooltip || ''} multiline>
              <Text fw={600} fz={20} c={'#000'}>
                {formattedAmount(value, isLoading)}{' '}
              </Text>
            </Tooltip>
          </Group>
        </div>
      ))}

      <DisplayAmount
        amount={pipelineStatusData?.currentARR.value}
        isLoading={isLoading}
        currency={currency}
        label='Total current pipeline'
        tooltip={pipelineStatusData?.currentARR.tooltip}
      />
    </Card>
  )
}
