import * as Yup from 'yup'

export const clientSchema = Yup.object({
  id: Yup.number().positive().required(),
  region: Yup.string().required(),
  name: Yup.string().required('Name is required'),
  website: Yup.string().required('Website is required'),
  logo: Yup.string().nullable(),
  active: Yup.boolean().required(),
  distributionPipelineVendorId: Yup.string().nullable(),
  salesDashboardVendorId: Yup.string().nullable(),
  currency: Yup.string().nullable(),
  signedLogoUrl: Yup.string().nullable(),
  showDistributionPipelineToExternalUser: Yup.boolean().nullable(),
  showSalesDashboardToExternalUser: Yup.boolean().nullable(),
  showLeadsDashboardToExternalUser: Yup.boolean().nullable(),
  JIWARegion: Yup.string().nullable(),
  showNotes: Yup.boolean().required(),
  showTeams: Yup.boolean().required(),
  FYEndMonth: Yup.string()
    .oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'])
    .required(),
  teamMembers: Yup.array()
    .of(
      Yup.object({
        member: Yup.number().required('Member is required'),
        role: Yup.string().required('Role is required'),
      })
    )
    .nullable(),
  deletedAt: Yup.string().nullable(),
  enableVendorSalesPersonFilter: Yup.boolean().nullable(),
})

export const clientSummarySchema = Yup.object({
  id: Yup.number().positive().required(),
  name: Yup.string().required('Name is required'),
  website: Yup.string().required('Website is required'),
  logo: Yup.string().nullable(),
  active: Yup.boolean().required(),
  distributionPipelineVendorId: Yup.string().nullable(),
  salesDashboardVendorId: Yup.string().nullable(),
  currency: Yup.string().nullable(),
  signedLogoUrl: Yup.string().nullable(),
  showDistributionPipelineToExternalUser: Yup.boolean().nullable(),
  showSalesDashboardToExternalUser: Yup.boolean().nullable(),
  showLeadsDashboardToExternalUser: Yup.boolean().nullable(),
  JIWARegion: Yup.string().nullable(),
  showNotes: Yup.boolean().required(),
  showTeams: Yup.boolean().required(),
  FYEndMonth: Yup.string()
    .oneOf(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'])
    .required(),
})

export type Client = Yup.InferType<typeof clientSchema>
export type ClientSummary = Yup.InferType<typeof clientSummarySchema>

export const createClientRequestSchema = clientSchema.pick([
  'name',
  'website',
  'distributionPipelineVendorId',
  'salesDashboardVendorId',
  'currency',
  'JIWARegion',
  'showDistributionPipelineToExternalUser',
  'showSalesDashboardToExternalUser',
  'showNotes',
  'showTeams',
  'showLeadsDashboardToExternalUser',
  'FYEndMonth',
  'teamMembers',
  'region',
  'enableVendorSalesPersonFilter',
])
export type CreateClientRequest = Yup.InferType<typeof createClientRequestSchema>

export const updateClientRequestSchema = clientSchema.pick([
  'id',
  'name',
  'website',
  'logo',
  'distributionPipelineVendorId',
  'salesDashboardVendorId',
  'currency',
  'JIWARegion',
  'showDistributionPipelineToExternalUser',
  'showSalesDashboardToExternalUser',
  'showLeadsDashboardToExternalUser',
  'showNotes',
  'showTeams',
  'FYEndMonth',
  'teamMembers',
  'region',
  'enableVendorSalesPersonFilter',
])
export type UpdateClientRequest = Yup.InferType<typeof updateClientRequestSchema> & {
  remove?: boolean
}
