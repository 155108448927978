import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Col,
  FileInput,
  Grid,
  Group,
  Image,
  Loader,
  Select,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { User, useUserRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { useInvalidateOpportunitiesCache } from '@ospace/distribution-pipeline'
import { NewTable, UserStatus } from '@ospace/shared'
import { RegionSelect } from '@ospace/shared/components/RegionSelect'
import { useCurrency } from '@ospace/shared/hooks/useCurrency'
import { useUsers } from '@ospace/user'
import { IconTrash } from '@tabler/icons-react'

type ClientFormProps = {
  clientId?: number // if not set, it is a create form
  isSubmitting: boolean
  initialValues: any
  onSubmit: any
  validationSchema?: any
}

const isNextGenStaff = (user: User) => {
  return (
    user?.enabled === true &&
    (user?.userStatus === UserStatus.CONFIRMED ||
      user?.userStatus === UserStatus.EXTERNAL_PROVIDER) &&
    user?.userAttributes?.some(
      (attr: any) =>
        attr.Name === 'email' && /@nextgen\.group|@nextgendistribution\.com\.au/.test(attr.Value)
    )
  )
}

export const ClientForm = (props: ClientFormProps) => {
  const { onSubmit, initialValues, validationSchema, clientId, isSubmitting } = props
  const currencies = useCurrency()
  const role = useUserRole()
  const isAdmin = role === userTypes.Admin
  const invalidateOpportunitiesCache = useInvalidateOpportunitiesCache({ clientId: clientId || 0 })

  const users = useUsers()

  const form = useForm({
    initialValues,
    validate: yupResolver(validationSchema),
  })

  const [preview, setPreview] = useState<any>()
  const handleFileChange = (files: any) => {
    form.setFieldValue('logoFile', files)

    // Display image preview
    if (files) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(files)
    } else {
      setPreview(null)
    }
  }

  const [teamOptions = [], setTeamOptions] = useState<{ value: any; label: string }[]>()

  useEffect(() => {
    if (users.data) {
      const filteredAndSortedUsers = users.data
        .filter((user: User) => isNextGenStaff(user))
        .sort((a: any, b: any) => a.name.localeCompare(b.name))

      const teamOptions = filteredAndSortedUsers.map((user: any) => ({
        value: user.id,
        label: `${user.name} - ${user.email}`,
      }))

      setTeamOptions(teamOptions)
    }
  }, [users.data])

  const [defaultCurrency, setDefaultCurrency] = useState<any>()

  useEffect(() => {
    if (currencies.data) {
      const selectedCurrency = currencies.data.filter((cu) => cu.code === form.values.currency)
      setDefaultCurrency(selectedCurrency[0] ? selectedCurrency[0]['code'] : '')
    }
  }, [currencies, form.values.currency])

  const fields = form.values.teamMembers?.map((_: any, index: any) => (
    <Group key={index} my='xs'>
      <Select
        size='md'
        name='member'
        placeholder='Team member'
        withinPortal
        {...form.getInputProps(`teamMembers.${index}.member`)}
        data={teamOptions.filter((d: any) => {
          if (form.getInputProps(`teamMembers.${index}.member`).value === d.value) return true

          return form.values.teamMembers.every((team: any) => team.member !== d.value)
        })}
        searchable
        style={{ flex: 1 }}
      />
      <TextInput
        size='md'
        placeholder='Designated role'
        withAsterisk
        {...form.getInputProps(`teamMembers.${index}.role`)}
      />
      <ActionIcon color='red' onClick={() => form.removeListItem('teamMembers', index)}>
        <IconTrash size='1rem' />
      </ActionIcon>
    </Group>
  ))

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid>
        <Col span={6} p={'xl'}>
          {form.values.active && (
            <Badge color={form.values.active ? 'green' : 'orange'} size='lg' mb='md'>
              {form.values.active === true
                ? 'Active'
                : form.values.active === false
                  ? 'Not Active'
                  : null}
            </Badge>
          )}
          <TextInput
            withAsterisk
            label='Name'
            placeholder='Specify your unique client name'
            {...form.getInputProps('name')}
            mb='md'
            size='md'
          />
          <RegionSelect
            inputProps={form.getInputProps('region')}
            onChange={(e) => {
              form.setFieldValue('region', e)
            }}
          />
          <TextInput
            withAsterisk
            label='Website'
            placeholder='Specify your website'
            {...form.getInputProps('website')}
            mb='md'
            size='md'
          />

          {currencies.status === 'loading' || currencies.status === 'idle' ? (
            <Loader variant='dots' />
          ) : (
            defaultCurrency !== undefined && (
              <Select
                mb='md'
                size='md'
                name='currency'
                withinPortal
                data={
                  currencies?.data?.map(({ code, name }) => ({
                    label: name,
                    value: code,
                  })) as any
                }
                placeholder='Currency'
                label='Specify the Currency'
                defaultValue={defaultCurrency}
                onChange={(e) => form.setFieldValue('currency', e || '')}
                clearable
              />
            )
          )}

          <FileInput
            placeholder='Upload your Vendors Logo here'
            onChange={handleFileChange}
            label='Vendor Logo'
            mb='md'
            size='md'
          />
          <Select
            mb='md'
            size='md'
            name='FYEndMonth'
            withinPortal
            {...form.getInputProps('FYEndMonth')}
            data={[
              {
                label: 'January',
                value: '1',
              },
              {
                label: 'February',
                value: '2',
              },
              {
                label: 'March',
                value: '3',
              },
              {
                label: 'April',
                value: '4',
              },
              {
                label: 'May',
                value: '5',
              },
              {
                label: 'June',
                value: '6',
              },
              {
                label: 'July',
                value: '7',
              },
              {
                label: 'August',
                value: '8',
              },
              {
                label: 'September',
                value: '9',
              },
              {
                label: 'October',
                value: '10',
              },
              {
                label: 'November',
                value: '11',
              },
              {
                label: 'December',
                value: '12',
              },
            ]}
            placeholder='Month'
            label='Show the last month of the financial year'
            value={form.values.FYEndMonth ? form.values.FYEndMonth.toString() : null}
            onChange={(e) => {
              form.setFieldValue('FYEndMonth', e)
            }}
            clearable
          />
          {preview && <Image src={preview} width={200} alt='Preview' mt={'md'} />}
          {form.values.signedLogoUrl && !preview ? (
            <Image src={form.values.signedLogoUrl} width={200} alt='Preview' mt={'md'} />
          ) : null}
        </Col>
        <Col span={6} p={'xl'}>
          {users.isLoading ? (
            <Loader variant={'dots'} />
          ) : (
            <Card padding='lg' radius='md' withBorder p={'xl'}>
              <Card.Section>
                <Title order={5}>Team members</Title>
                {fields}
                <Button
                  size='sm'
                  onClick={() =>
                    form.insertListItem('teamMembers', {
                      member: undefined,
                      role: '',
                    })
                  }
                >
                  Add member
                </Button>
                <Switch
                  checked={form.values.showTeams}
                  onChange={() => form.setFieldValue('showTeams', !form.values.showTeams)}
                  label='Show team members'
                  mt={'md'}
                  size='md'
                  disabled={!isAdmin}
                />
              </Card.Section>
            </Card>
          )}

          <Card padding='lg' radius='md' withBorder p={'xl'} mt={'xl'}>
            <Card.Section>
              <Title order={5}>Distribution Pipeline</Title>
              <TextInput
                label='Vendor Id'
                placeholder='Specify your unique client name'
                {...form.getInputProps('distributionPipelineVendorId')}
                size='md'
              />
              <Switch
                checked={form.values.showDistributionPipelineToExternalUser}
                onChange={() =>
                  form.setFieldValue(
                    'showDistributionPipelineToExternalUser',
                    !form.values.showDistributionPipelineToExternalUser
                  )
                }
                label='Show distribution pipeline to external user'
                mt={'md'}
                size='md'
                disabled={!isAdmin}
              />
              <Switch
                checked={form.values.showNotes}
                onChange={() => form.setFieldValue('showNotes', !form.values.showNotes)}
                label='Show notes'
                mt={'md'}
                size='md'
                disabled={!isAdmin}
              />
              {clientId && form.values.distributionPipelineVendorId ? (
                <Button
                  loading={invalidateOpportunitiesCache.isLoading}
                  onClick={() => {
                    invalidateOpportunitiesCache.mutateAsync()
                  }}
                  mt='md'
                >
                  Invalidate Vendor Cache
                </Button>
              ) : null}
            </Card.Section>
          </Card>
          <Card padding='lg' radius='md' withBorder p={'xl'} mt={'xl'}>
            <Card.Section>
              <Title order={5}>Sales Dashbaord</Title>
              <TextInput
                label='Vendor Id'
                placeholder='Specify your unique client name'
                {...form.getInputProps('salesDashboardVendorId')}
                size='md'
              />
              <Select
                mb='md'
                mt='md'
                size='md'
                name='JIWARegion'
                withinPortal
                data={[
                  {
                    label: '🇦🇺 Australia',
                    value: 'au',
                  },
                  {
                    label: '🇳🇿 New Zealand',
                    value: 'nz',
                  },
                  {
                    label: '🇸🇬 Singapore',
                    value: 'asia',
                  },
                  {
                    label: '🇮🇩 Indonesia',
                    value: 'indo',
                  },
                  {
                    label: '🇸🇽 Malaysia',
                    value: 'my',
                  },
                  {
                    label: '🇵🇭 Philippines',
                    value: 'ph',
                  },
                ]}
                {...form.getInputProps('JIWARegion')}
                placeholder='JIWA Region'
                label='JIWA Region'
                defaultValue={'au'}
                onChange={(e) => form.setFieldValue('JIWARegion', e || '')}
                clearable
              />
              <Switch
                checked={form.values.showSalesDashboardToExternalUser}
                onChange={() =>
                  form.setFieldValue(
                    'showSalesDashboardToExternalUser',
                    !form.values.showSalesDashboardToExternalUser
                  )
                }
                label='Show sales dashboard to external user'
                mt={'md'}
                size='md'
                disabled={!isAdmin}
              />
            </Card.Section>
          </Card>
          <Card padding='lg' radius='md' withBorder p={'xl'} mt={'xl'}>
            <Card.Section>
              <Title order={5}>Leads Dashbaord</Title>
              <Switch
                checked={form.values.showLeadsDashboardToExternalUser}
                onChange={() =>
                  form.setFieldValue(
                    'showLeadsDashboardToExternalUser',
                    !form.values.showLeadsDashboardToExternalUser
                  )
                }
                label='Show leads dashboard to external user'
                mt={'md'}
                size='md'
                disabled={!isAdmin}
              />
            </Card.Section>
          </Card>
          <Card padding='lg' radius='md' withBorder p={'xl'} mt={'xl'}>
            <Card.Section>
              <Title order={5}>Vendor Sales Person Filter</Title>
              <Switch
                checked={form.values.enableVendorSalesPersonFilter}
                onChange={() =>
                  form.setFieldValue(
                    'enableVendorSalesPersonFilter',
                    !form.values.enableVendorSalesPersonFilter
                  )
                }
                label='Enable vendor sales person filter'
                mt={'md'}
                size='md'
                disabled={!isAdmin}
              />
            </Card.Section>
          </Card>
        </Col>
        <Col span={12} p={'xl'}>
          <Group position='left'>
            <Text c='dimmed' fw={700} fz='md'>
              Associated Users
            </Text>
          </Group>
          {users.isLoading ? (
            <Loader variant={'dots'} />
          ) : (
            <NewTable
              hideSearch
              colDef={[
                {
                  key: 'edit',
                  label: 'Edit',
                  element: (row: any) => (
                    <Button
                      component={Link}
                      to={`/user/edit/${row.identityId}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      size='xs'
                    >
                      Edit
                    </Button>
                  ),
                },
                {
                  key: 'email',
                  label: 'Email',
                },
                {
                  key: 'firstName',
                  label: 'First Name',
                },
                {
                  key: 'lastName',
                  label: 'Last Name',
                },
                {
                  key: 'jobTitle',
                  label: 'Job title',
                },
                {
                  key: 'enabled',
                  label: 'Enabled',
                },
                {
                  key: 'userStatus',
                  label: 'Status',
                },
                {
                  key: 'type',
                  label: 'Type',
                },
                {
                  key: 'enableProgramNotifications',
                  label: 'Program updates',
                },
                {
                  key: 'enableDashboardNotifications',
                  label: 'Lead/Pipeline/Sales updates',
                },
              ]}
              tableData={
                users?.data
                  ?.filter((user) => user.clients.find((c) => c.id === clientId))
                  .map((u) => ({
                    ...u,
                    enableDashboardNotifications: u.enableDashboardNotifications ? 'Yes' : 'No',
                    enableProgramNotifications: u.enableProgramNotifications ? 'Yes' : 'No',
                    enabled: u.enabled ? 'Yes' : 'No',
                  })) || []
              }
              tableProps={{
                itemsPerPage: 10,
                rowKey: 'email',
              }}
            />
          )}
        </Col>
      </Grid>

      <Group position='right' m='md'>
        <Button type='submit' loading={isSubmitting} size='md'>
          Submit
        </Button>
        <Button component={Link} to={'/'} size='md' color='gray'>
          Cancel
        </Button>
      </Group>
    </form>
  )
}
