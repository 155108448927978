import { Metrics, sum } from '@ospace/shared'
import { upperFirst } from 'lodash'

import { MetricSummary } from '../data-and-intelligence/MetricSummary'

import { WeeklyUpdades } from './WeeklyUpdates'

export const CampaignOverview = (props: { campaign: any; dealStatus: any }) => {
  const { campaign } = props
  const lastUpdate = campaign?.objective?.results[campaign.objective.results.length - 1]

  const renderMetricInfo = (title: string, metrics: (keyof typeof Metrics)[]) => ({
    title,
    metrics: metrics
      // .filter((key) => campaign.objective[key])
      .map((key) => ({
        title: Metrics[key],
        link: `/campaign/data-intel/${campaign.id}/dashboard/${key}`,
        target: campaign.objective[key],
        enabled: campaign.objective[`is${upperFirst(key)}Enabled`],
        value: sum(campaign.objective.results, key),
      })),
  })

  return (
    <>
      {!!lastUpdate && <WeeklyUpdades lastUpdate={lastUpdate} />}

      <div className='row g-5 g-xl-8'>
        {!!campaign.objective?.isDataIntelligenceActive && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={renderMetricInfo('Data and Intelligence', [
                'intents',
                'companiesDiscovered',
                'contactsDiscovered',
              ])}
            />
          </div>
        )}

        {!!campaign.objective?.isTeleProspectingActive && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={renderMetricInfo('Tele Prospecting', [
                'telesaleHours',
                'meetingBooked',
                'targetPipelineValue',
                'dealRegDone',
              ])}
            />
          </div>
        )}

        {!!campaign.objective?.isDigitalProspectingActive && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={renderMetricInfo('Digital Prospecting', [
                'digitalLeads',
                'digitalProspectingMeetingBooked',
                'connectionRequestsSent',
                'connectionRequestsAccepted',
                'impressions',
                'campaignClicks',
              ])}
            />
          </div>
        )}
      </div>
    </>
  )
}
