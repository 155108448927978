import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useCheckRole } from '@ospace/auth'
import { userTypes } from '@ospace/core-auth'
import { PageTitle } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'
import dayjs from 'dayjs'
import { pick } from 'lodash'

import { CampaignForm } from '../components/create-campaign/CampaignForm'
import { useCampaign } from '../hooks'

type Props = { campaignId: number }
export const EditCampaignPage = (props: Props) => {
  const { status, data: campaign, error } = useCampaign(props.campaignId)

  if (status === 'idle' || status === 'loading')
    return (
      <>
        <PageTitle>Edit Campaign</PageTitle>
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      </>
    )

  if (status === 'error' || campaign === undefined)
    return (
      <>
        <PageTitle>Edit Campaign</PageTitle>
        <span>Error: {(error as any).message}</span>
      </>
    )

  const campaignDTO = {
    ...campaign.objective,
    id: campaign.id,
    client: Number(campaign.client.id),
    crm: campaign.crm,
    name: campaign.name,
    ...pick(campaign.objective, [
      'isIntentsEnabled',
      'isCompaniesDiscoveredEnabled',
      'isContactsDiscoveredEnabled',
      'isTelesaleHoursEnabled',
      'isMeetingBookedEnabled',
      'isTargetPipelineValueEnabled',
      'isDealRegDoneEnabled',
      'isDigitalLeadsEnabled',
      'isDigitalProspectingMeetingBookedEnabled',
      'isConnectionRequestsSentEnabled',
      'isConnectionRequestsAcceptedEnabled',
      'isImpressionsEnabled',
      'isCampaignClicksEnabled',
    ]),
    description: campaign.description || '',
    startDate: dayjs(campaign?.startDate).format('YYYY-MM-DD'),
    endDate: campaign?.endDate ? dayjs(campaign?.endDate).format('YYYY-MM-DD') : null,
    isDataIntelligenceActive: !!campaign.objective?.isDataIntelligenceActive,
    isTeleProspectingActive: !!campaign.objective?.isTeleProspectingActive,
    isDigitalProspectingActive: !!campaign.objective?.isDigitalProspectingActive,
    status: campaign.status,
    clientId: campaign.client?.id,
    lookerClientId: campaign.lookerClientId ? campaign.lookerClientId : null,
    intentReportConfigurations: campaign.intentReportConfigurations.map(
      // TODO: see Q in models.Campaign, this shouldn't be necessary
      (iRC) => ({ accountType: iRC.accountType, reportId: iRC.configuration.reportId })
    ),
    icps: campaign.icps.map((icp) => {
      return {
        id: icp.id,
        name: icp.name,
        industryIds: icp.industryIds,
        revenueRangeIds: icp.revenueRangeIds,
        employeeRangeIds: icp.employeeRangeIds,
        locations: icp.locations.map((l) => ({
          id: l.id,
          stateCode: l.stateCode,
          countryCode: l.countryCode,
        })),
      }
    }),
    products: campaign.products,
    emailRate: campaign.emailRate,
    linkedinRate: campaign.linkedinRate,
    BANTRate: campaign.BANTRate,
    ltnRate: campaign.ltnRate,
    htnRate: campaign.htnRate,
    averageDealSize: campaign.averageDealSize,
    averageCustomerTermInYears: campaign.averageCustomerTermInYears,
    programTermInMonths: campaign.programTermInMonths,
  }

  return (
    <>
      <PageTitle>Edit Campaign</PageTitle>
      <CampaignForm type='edit' campaign={campaignDTO} />
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])

  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <EditCampaignPage campaignId={campaignId} />
}

export const EditCampaignPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
